<template>
  <div class="py-16">
    <v-snackbar v-model="showSnackBar" color="#000" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout
      fill-height
      px-0
      px-sm-3
      px-md-10
      px-lg-15
      wrap
      justify-center
      pb-16
    >
      <v-flex xs12 md11 align-self-center pa-2>
        <v-card tile flat>
          <v-layout wrap>
            <v-flex xs12 align-self-center pa-2>
              <v-card
                color="#08130b"
                class="rounded-lg"
                style="border-bottom:5px solid #FF6907"
              >
                <v-layout wrap style="color:#ffffff" pa-6>
                  <v-flex xs12 align-self-center pb-3>
                    <v-layout wrap>
                      <v-flex xs12 align-self-start pa-1 text-right>
                        <v-icon
                          dark
                          large
                          color="#ffffff"
                          @click="appLogout"
                          title="Log Out"
                          >mdi-power</v-icon
                        >
                      </v-flex>
                      <v-flex
                        xs12
                        align-self-center
                        text-center
                        v-if="userdata.photo"
                        class="rounded-lg"
                      >
                        <v-avatar color="primary" size="170px">
                          <v-img
                            height="170px"
                            contain
                            class="rounded-lg"
                            :src="mediaURL + userdata.photo"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#000"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs12 align-self-center v-else>
                        <v-img
                          height="170px"
                          contain
                          :src="require('./../../../assets/Images/avat2.png')"
                        ></v-img>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 align-self-center pa-1 text-center>
                    <span
                      style="font-size:32px"
                      class="text-capitalize sofiaProBold"
                    >
                      {{ userdata.name }}
                    </span>
                  </v-flex>
                  <v-flex xs12 align-self-center pa-1 text-center>
                    <span
                      v-if="userdata.email"
                      style="font-size:22px"
                      class=" sofiaProRegular"
                    >
                      {{ userdata.email }}
                    </span>
                    <span
                      v-if="userdata.phone"
                      style="font-size:22px"
                      class=" sofiaProRegular"
                    >
                      , {{ userdata.phone }}
                    </span>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    px-2
                    v-if="
                      userdata.internshipStatus == 'Completed' ||
                        userdata.internshipStatus == 'completed'
                    "
                  >
                    <v-layout wrap v-if="copyURL">
                      <v-flex
                        xs10
                        align-self-center
                        text-center
                        class="hidden-xs-only"
                      >
                        <v-card outlined elevation="0" tile :title="copyURL">
                          <v-layout wrap py-1>
                            <v-flex>
                              <span
                                :style="{
                                  'font-size':
                                    $vuetify.breakpoint.name == 'xs'
                                      ? '10px'
                                      : $vuetify.breakpoint.name == 'sm'
                                      ? '13px'
                                      : $vuetify.breakpoint.name == 'md'
                                      ? '10px'
                                      : $vuetify.breakpoint.name == 'lg'
                                      ? '13px'
                                      : '13px',
                                }"
                                >{{ copyURL.slice(0, 35) }}..</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                      <v-flex xs12 sm2 py-2 py-sm-0>
                        <v-card
                          style="cursor: pointer"
                          outlined
                          color="#FF6907"
                          elevation="0"
                          tile
                        >
                          <v-layout wrap py-1>
                            <v-flex text-center>
                              <span
                                @click.stop.prevent="copyImageUrl"
                                style="color: #fff; text-decoration: none"
                                :style="{
                                  'font-size':
                                    $vuetify.breakpoint.name == 'xs'
                                      ? '13px'
                                      : $vuetify.breakpoint.name == 'sm'
                                      ? '16px'
                                      : $vuetify.breakpoint.name == 'md'
                                      ? '13px'
                                      : $vuetify.breakpoint.name == 'lg'
                                      ? '16px'
                                      : '16px',
                                }"
                              >
                                <span class="hidden-xs-only">
                                  copy
                                </span>
                                <span class="hidden-sm-and-up">
                                  copy Profile URL
                                </span>
                              </span>
                              <input
                                type="hidden"
                                id="testing-code"
                                :value="copyURL"
                              />
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-right
                    px-2
                    v-if="
                      userdata.internshipStatus == 'Completed' ||
                        userdata.internshipStatus == 'completed'
                    "
                  >
                    <a
                      :href="
                        'https://api.leopardtechlabs.in/internship/candidate/getcertificate?id=' +
                          userdata._id
                      "
                    >
                      <v-btn
                        title="Click to download your internship certificate"
                        dark
                        tile
                        color="#FF6907"
                      >
                        <span>Download Certificate</span>
                      </v-btn>
                    </a>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 align-self-center pa-2>
              <v-card flat>
                <v-layout wrap>
                  <v-flex xs12 align-self-start text-left pt-3>
                    <v-layout wrap>
                      <v-flex xs12 pa-1 align-self-center>
                        <span
                          class="sofiaProSemiBold"
                          style="border-bottom:2px solid #FF6907;font-size:20px"
                          >Internship Details
                        </span>
                      </v-flex>

                      <v-flex xs12 pa-1 align-self-center v-if="userdata.type">
                        <span v-if="userdata.type" class="sofiaProRegular"
                          >Category : {{ userdata.type }}
                        </span>
                      </v-flex>
                      <v-flex xs12 pa-1 align-self-center v-if="userdata.batch">
                        <span v-if="userdata.batch.name" class="sofiaProRegular"
                          >Batch : {{ userdata.batch.name }} (
                          <span v-if="userdata.batch.from">
                            {{ userdata.batch.from.slice(0, 10) }}
                          </span>
                          <span v-if="userdata.batch.to">
                            - {{ userdata.batch.to.slice(0, 10) }}
                          </span>
                          )
                        </span>
                      </v-flex>

                      <v-flex
                        xs12
                        pa-1
                        align-self-center
                        v-if="userdata.numberOfMonths"
                      >
                        <span
                          v-if="userdata.numberOfMonths"
                          class="sofiaProRegular"
                          >Period of Internship in months :
                          {{ userdata.numberOfMonths }}
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        pa-1
                        align-self-center
                        v-if="userdata.internshipStatus"
                      >
                        <span
                          v-if="userdata.internshipStatus"
                          class="sofiaProRegular"
                          >Status : {{ userdata.internshipStatus }}
                        </span>
                      </v-flex>

                      <v-flex
                        xs12
                        pa-1
                        align-self-center
                        v-if="userdata.project"
                      >
                        <v-card flat>
                          <v-layout wrap justify-center>
                            <v-flex xs12 align-self-center text-left py-1>
                              <span
                                v-if="userdata.project"
                                class="sofiaProRegular"
                              >
                                <span style="border-bottom:2px solid #FF6907">
                                  About Project</span
                                >
                              </span>
                            </v-flex>
                            <v-flex xs12 align-self-center text-left>
                              <span
                                v-if="userdata.project.name"
                                class="sofiaProRegular"
                              >
                                <span class="sofiaProSemiBold">
                                  {{ userdata.project.name }}
                                </span>
                                <br />
                                <span>
                                  {{ userdata.project.description }}
                                </span>
                                <br />
                                <br />
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 align-self-start text-left>
                    <v-layout wrap>
                      <v-flex xs12 pa-1 align-self-center>
                        <span
                          class="sofiaProSemiBold"
                          style="border-bottom:2px solid #FF6907;font-size:20px"
                          >Student Details
                        </span>
                      </v-flex>

                      <v-flex
                        xs12
                        pa-1
                        align-self-center
                        v-if="userdata.department"
                      >
                        <span v-if="userdata.department" class="sofiaProRegular"
                          >Department : {{ userdata.department }}
                        </span>
                      </v-flex>

                      <v-flex
                        xs12
                        pa-1
                        align-self-center
                        v-if="userdata.collageBatch"
                      >
                        <span
                          v-if="userdata.collageBatch"
                          class="sofiaProRegular"
                          >Batch : {{ userdata.collageBatch }}
                        </span>
                      </v-flex>
                      <v-flex xs12 pa-1 align-self-center>
                        <span class="sofiaProRegular"
                          >Amal Jyothi College of Engineering, Koovappally
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      userdata: [],
      copyURL: null,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    copyImageUrl() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful
          ? " Image URL copied successfully"
          : "unsuccessful";
        this.msg = msg;
        this.showSnackBar = true;
      } catch (err) {
        this.msg = "Oops, unable to copy";
        this.showSnackBar = true;
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/internuser/profile",
        headers: {
          "x-auth-token": localStorage.getItem("internToken"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.userdata = response.data.data;
            this.copyURL =
              "https://leopardtechlabs.com/InternshipCandidate?id=" +
              this.userdata._id;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.conGrad {
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);
}
.graddy {
  background: transparent
    linear-gradient(
      100deg,
      #f5a624 0%,
      #f9b52c 44%,
      #fbba2f 59%,
      #fdc033 78%,
      #fdc033 78%,
      #ffc837 100%
    );
}
</style>
